<template>
  <div>
    <portal to="header">
      <terminal-header
        :title="$t('Sorting')"
        @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

    <div v-if="action === 'scanBasket'">
      <assistant-object :data="assistantObject" />
    </div>

    <div v-else>
      <sorting-content
        :barcode="barcode"
        :queues="queues"
        :place="place"
        @block="handleBlock"
        @unblock="handleUnblock"
        @reset="handleReset"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'Sorting',
  components: {
    SortingContent: defineAsyncComponent(() => import('./SortingContent.vue'))
  },
  data () {
    return {
      action: 'scanBasket',
      queues: [],
      isBlocked: false,
      barcode: null,
      place: null,
      assistantObject: {
        title: 'Please scan',
        caption: 'Sorting Location',
        captionColor: 'amber',
        image: 'scanSortingPlace'
      }
    }
  },
  computed: {
    tilesSchema () {
      return []
    }
  },
  unmounted () {
    this.deleteAllHelpers()

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'deleteAllHelpers'
    ]),
    handleBlock () {
      this.isBlocked = true
    },
    handleUnblock () {
      this.isBlocked = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.isBlocked = false
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.place = null
    },
    handleBarcode (barcode) {
      if (this.action === 'scanBasket') {
        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)
          return Promise.resolve(null)
        }

        return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            if (place.type !== 'sorting') {
              this.addErrorNotification('You must scan sorting place!')
              return
            }

            const query = {
              filter: [
                { type: 'in', field: 'adapter', values: ['sorting','advanced_sorting'] },
                { type: 'eq', field: 'warehouse', value: place._embedded.warehouse.id }
              ]
            }

            return this.$service.picking.getAll(query)
              .then(({ items }) => {
                this.place = barcode.value
                this.$channels.user.publish('openPlace', this.place, place)

                this.queues = items.map(data => {
                  return {
                    type: 'Orderadmin\\Storage\\Entity\\Picking\\Queue',
                    event: 'storage.tasks.queue.opened',
                    data
                  }
                })

                this.action = 'next'
              })
          })
      }

      this.barcode = barcode
    }
  }
}
</script>
